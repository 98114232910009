import React from "react"
import { graphql } from "gatsby"
import { injectIntl, Link } from "gatsby-plugin-react-intl"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import HeaderColor from "../components/HeaderColor"
import HeaderCover from "../components/HeaderCover"
import { ButtonTransparent } from "../components/Button"
import Footer from "../components/Footer"
import { Row, Col, Empty } from "antd"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import styled from "@emotion/styled"

const RowEmptyWrapper = styled(Row)`
  margin: 120px 0;
`
const CategoryWrapper = styled.div`
  margin: 60px 0 0 0;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    li {
      margin: 25px 15px;
      a {
        font-weight: 400;
        font-size: 18px;
        color: #181818;
        padding-bottom: 20px;
        border-bottom: 2px solid transparent;
        &.active {
          color: #7497b2;
          border-bottom: 2px solid #7497b2;
        }
        &:hover {
          color: #7497b2;
        }
      }
    }
  }

  @media only screen and (min-width: 768px) {
    margin: 80px 0 20px 0;
  }
`
const QueryWrapper = styled.div`
  margin: 60px 0 80px 0;

  @media only screen and (min-width: 768px) {
    margin: 80px 0 100px 0;
  }
`
const CardWrapper = styled.div`
  position: relative;
  padding-bottom: 62px;
  background: #fff;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  height: 100%;
  min-height: 100%;
  &:hover {
    box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.2);
  }
  .featuredWrapper {
    display: flex;
    position: relative;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 401px;
    a {
      display: inline-flex;
    }
    img {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    span {
      position: absolute;
      left: 25px;
      bottom: -21px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 140px;
      max-width: calc(100% - 50px);
      min-height: 42px;
      padding: 10px 15px;
      font-family: "Kanit", sans-serif;
      font-weight: 400;
      font-size: 16px;
      color: #fff;
      background: #7497b2;
      border-radius: 5px;
    }
  }
  .contentWrapper {
    padding: 40px 25px 15px 25px;
    font-family: "Kanit", sans-serif;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    .date {
      font-weight: 300;
      font-size: 16px;
      color: #646464;
      margin-bottom: 0;
    }
    h3 {
      font-weight: 400;
      font-size: 24px;
      color: #181818;
      margin-bottom: 10px;
    }
    .excerpt {
      font-weight: 400;
      font-size: 16px;
      color: #646464;
      margin-bottom: 25px;
    }
  }
  .buttonWrapper {
    position: absolute;
    left: 25px;
    bottom: 30px;
  }
`
const PaginateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`

const NewsPage = ({ data, pageContext, intl, location }) => {
  const locale = intl && intl.locale ? intl.locale : `th`
  const { pageFields, newsPosts, newsCategory } = data
  const { seoTitle, seoDescription, coverImage } = pageFields.edges[0].node
  const getCoverImage = getImage(coverImage)

  const categories = newsCategory.edges
  const posts = newsPosts.edges
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  let prevPage = `/news/` + (currentPage - 1) + `/`
  if (Number(currentPage) < 3) {
    prevPage = `/news/`
  }
  const nextPage = `/news/` + (currentPage + 1) + `/`
  const isActive = location.pathname.indexOf("/news") !== -1

  return (
    <Layout>
      <Seo lang={locale} title={seoTitle} description={seoDescription} />
      <HeaderColor />
      <HeaderCover
        bgImage={getCoverImage}
        coverText={intl.formatMessage({ id: "navMenu3" })}
      />
      {data ? (
        <>
          <CategoryWrapper>
            <div className="gb-container">
              <Row>
                <ul>
                  <li key="all">
                    <Link
                      to="/news/"
                      activeClassName="active"
                      className={isActive ? `active` : ``}
                    >
                      {intl.formatMessage({ id: "textAll" })}
                    </Link>
                  </li>
                  {categories &&
                    categories.map((cat, index) => {
                      return (
                        <li key={index}>
                          <Link
                            to={`/news-category/${cat.node.slug}/`}
                            activeClassName="active"
                          >
                            {cat.node.name}
                          </Link>
                        </li>
                      )
                    })}
                </ul>
              </Row>
            </div>
          </CategoryWrapper>
          <QueryWrapper>
            <div className="gb-container">
              <Row gutter={[16, 16]}>
                {posts &&
                  posts.map((post, index) => {
                    const getFeatured = getImage(post.node.featured)
                    let multiCat = ""
                    if (post.node.categories) {
                      post.node.categories.map(cat => {
                        multiCat += cat.name + ", "
                        return true
                      })
                    }

                    return (
                      <Col key={index} xs={24} sm={12} lg={8}>
                        <CardWrapper>
                          <div className="featuredWrapper">
                            <Link to={`/news/${post.node.slug}/`}>
                              <GatsbyImage image={getFeatured} alt="" />
                            </Link>
                            <span>
                              {multiCat &&
                                multiCat.substring(0, multiCat.length - 2)}
                            </span>
                          </div>
                          <div className="contentWrapper">
                            <p className="date">{post.node.createdAt}</p>
                            <Link to={`/news/${post.node.slug}/`}>
                              <h3
                                dangerouslySetInnerHTML={{
                                  __html: post.node.title,
                                }}
                              />
                            </Link>
                            <p
                              className="excerpt"
                              dangerouslySetInnerHTML={{
                                __html: post.node.excerpt.excerpt,
                              }}
                            />
                          </div>
                          <div className="buttonWrapper">
                            <ButtonTransparent
                              cta={`/news/${post.node.slug}/`}
                              text={intl.formatMessage({
                                id: "textReadDetails",
                              })}
                            />
                          </div>
                        </CardWrapper>
                      </Col>
                    )
                  })}
              </Row>
              {numPages > 1 && (
                <Row>
                  <Col span={24}>
                    <PaginateWrapper>
                      <ul className="ant-pagination">
                        {isFirst ? (
                          <li
                            key="prev"
                            className="ant-pagination-prev ant-pagination-disabled"
                          >
                            <button
                              className="ant-pagination-item-link"
                              type="button"
                              tabIndex="-1"
                              disabled
                            >
                              <LeftOutlined />
                            </button>
                          </li>
                        ) : (
                          <li key="prev" className="ant-pagination-item">
                            <Link to={prevPage} rel="prev">
                              <LeftOutlined />
                            </Link>
                          </li>
                        )}
                        {Array.from({ length: numPages }, (_, i) => (
                          <li
                            key={`pagination-number${i + 1}`}
                            className={
                              i + 1 === currentPage
                                ? "ant-pagination-item ant-pagination-item-active"
                                : "ant-pagination-item"
                            }
                          >
                            <Link
                              to={`/news/${i < 1 ? "" : i + 1 + "/"}`}
                              className={i + 1 === currentPage ? `active` : ``}
                            >
                              {i + 1}
                            </Link>
                          </li>
                        ))}
                        {isLast ? (
                          <li
                            key="next"
                            className="ant-pagination-next ant-pagination-disabled"
                          >
                            <button
                              className="ant-pagination-item-link"
                              type="button"
                              tabIndex="-1"
                              disabled
                            >
                              <RightOutlined />
                            </button>
                          </li>
                        ) : (
                          <li key="next" className="ant-pagination-item">
                            <Link to={nextPage} rel="next">
                              <RightOutlined />
                            </Link>
                          </li>
                        )}
                      </ul>
                    </PaginateWrapper>
                  </Col>
                </Row>
              )}
            </div>
          </QueryWrapper>
        </>
      ) : (
        <RowEmptyWrapper>
          <Col span={24}>
            <Empty />
          </Col>
        </RowEmptyWrapper>
      )}
      <Footer />
    </Layout>
  )
}

export default injectIntl(NewsPage)

export const query = graphql`
  query NewsPostsQuery(
    $skip: Int!
    $limit: Int!
    $locale: String
    $language: String
  ) {
    pageFields: allContentfulNewsPage(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          seoTitle
          seoDescription
          coverImage {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
    newsCategory: allContentfulNewsCategory(
      sort: { order: ASC, fields: createdAt }
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          slug
          name
        }
      }
    }
    newsPosts: allContentfulNewsPosts(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: createdAt }
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          slug
          title
          createdAt(formatString: "DD MMM YYYY", locale: $language)
          featured {
            gatsbyImageData (
              quality: 100,
              formats: [AUTO, WEBP],
              placeholder: BLURRED
            )
          }
          excerpt {
            excerpt
          }
          categories {
            name
          }
        }
      }
    }
  }
`
